/* eslint-disable no-irregular-whitespace */
<template>
  <App
    title="试驾详情"
    @click-right="onClickRight"
    left-arrow
  >
    <div slot="right">
      <span v-auth="'TestDriveDetails-OperatingRecord'">操作记录</span>
    </div>
    <div class="user">
      <div class="user-detail">
        <div class="user-avator"></div>
        <div class="user-info">
          <h5 class="user-info-name">{{dataDetail.accountName}}</h5>
          <span class="user-info-mobile">{{dataDetail.accountPhone}}</span>
        </div>
      </div>
      <div
        class="user-contact"
      >
        <div class="img-wrap"></div>
        <div class="img-wrap"><a :href="`tel:${dataDetail.accountPhone}`"></a></div>
      </div>
    </div>
    <DynamicForm
      v-model="model"
      :rules="rules"
      :fieldData="fieldData"
    />
    <div
      slot="footer"
      class="footer"
    >
      <div class="footer-wrap">
        <div v-auth="'TestDriveDetails-CancelTestDrive'">
          <Button
            @click="cancelled"
            class="cancel"
            v-if=" (userInfoId === dataDetail.createConsultantId || userInfoId === dataDetail.ownerId) && (dataDetail.testDriveStatus==='OVERDUE'||dataDetail.testDriveStatus==='SCHEDULED'||dataDetail.testDriveStatus==='CONFIRMED')"
          >取消试驾</Button>
        </div>
        <div v-auth="'TestDriveDetails-UpdTestDrive'">
          <Button
            @click="amendTestdrive"
            class="opption"
            v-if=" (userInfoId === dataDetail.createConsultantId || userInfoId === dataDetail.ownerId) && (dataDetail.testDriveStatus==='OVERDUE'||dataDetail.testDriveStatus==='SCHEDULED'||dataDetail.testDriveStatus==='CONFIRMED')"
          >修改试驾</Button>
        </div>
        <div v-auth="'TestDriveDetails-AffirmTestDrive'">
          <Button
            @click="confirmed"
            class="opption"
            v-if=" (userInfoId === dataDetail.createConsultantId || userInfoId === dataDetail.ownerId || userInfoId === dataDetail.coPilotId) && (dataDetail.testDriveStatus==='OVERDUE'||dataDetail.testDriveStatus==='SCHEDULED')"
          >确认试驾</Button>
        </div>
        <div v-auth="'TestDriveDetails-CompleteTestDrive'">
          <Button
            @click="completed"
            class="complit"
            v-if=" (userInfoId === dataDetail.createConsultantId || userInfoId === dataDetail.ownerId) && (dataDetail.testDriveStatus==='OVERDUE'||dataDetail.testDriveStatus==='SCHEDULED'|| dataDetail.testDriveStatus==='CONFIRMED')"
          >完成试驾</Button>
        </div>
        <div v-auth="'TestDriveDetails-AllotCoPilot'">
          <Button
            type="primary"
            @click="changePilot"
            v-if="(!dataDetail.coPilotId) && (dataDetail.testDriveStatus=== 'OVERDUE' || dataDetail.testDriveStatus === 'SCHEDULED')"
            class="complit"
          >分配Co-Pilot</Button>
        </div>
      </div>
    </div>
  </App>
</template>
<script>
import Vue from 'vue'
import { Button, Dialog, Toast } from 'vant'
import DynamicForm from '_c/dynamicForm'
import { orgCode } from '@/api/chargequery'
// eslint-disable-next-line
import { testdriveDetail, changeStatus, testdriveUpload } from '@/api/testdrive/index'
export default {
  name: 'testDriveDetail',
  components: {
    DynamicForm,
    Button
  },
  data () {
    return {
      id: '',// 当前订单的id
      userInfoId: '',
      roleCode: '',
      model: {
        status: '',
        storeName: '',
        type: '',
        coPilotName: '',
        appointmentDate: '',
        vehicleName: '',
        appointmentTime: '',
        ownerName: '',
        testDriveProtocol: []
      },
      fieldData: [
        {
          data: [
            { label: '状态', key: 'status', type: 'text', props: { readonly: true } },
            { label: '试驾门店', key: 'storeName', type: 'text', props: { readonly: true } },
            { label: '试驾类型', key: 'type', type: 'text', props: { readonly: true } },
            { label: 'Co-Pilot', key: 'coPilotName', type: 'text', visible: false, props: { readonly: true } },
            { label: '预约日期', key: 'appointmentDate', type: 'text', props: { readonly: true } },
            { label: '试驾车辆', key: 'vehicleName', type: 'text', props: { readonly: true } },
            { label: '预约时间', key: 'appointmentTime', type: 'text', props: { readonly: true } },
            { label: '归属顾问', key: 'ownerName', type: 'text', props: { readonly: true } },
            { label: '试驾协议', key: 'testDriveProtocol', type: 'uploader', props: { accept: ['.png', '.jpg', '.jpeg'], previewSize: '40px', maxSize: 5 * 1024 * 1024, 'inputAlign': 'left' } }
          ]
        },
        // {
        //   title: '试驾协议(必传)',
        //   data: [

        //   ]
        // }
      ],
      rules: {
        hhh: [
          { required: true, message: '请上传试驾协议' }
        ]
      },
      dataDetail: {},// 返回的详情数据
      testdriveType: [],// 试驾类型type集合
      testdriveStatus: [], // 排程状态集合
      fileList: [],// 上传的协议列表
      totalSize: 0// 上传文件的总大小
    }
  },
  watch: {
    'model.testDriveProtocol': {
      handler (value) {
        // 判断总文件大小
        if (value && value.length > 1) {
          const list = value.map(el => {
            return el.file.size
          })
          const size = list.reduce((pre, cur) => {
            return ((pre + cur) / 1024) / 1024
          }, 0)
          if (size > 5) {
            Toast('上传的总大小不能超过5M')
          }
        }
      },
      deep: true

    }
  },
  methods: {
    // beforeRead (file) {
    //   console.log('file', file);
    //   if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png') {
    //     Toast('请上传jpg,png,jpeg 格式图片');
    //     return false;
    //   }
    //   return true;
    // },
    // // 自定义上传方法
    // afterRead (file) {

    //   this.totalSize += (file.file.size / 1024) / 1024
    //   if (this.totalSize >= 5) {
    //     Toast('上传的总大小不能超过5M')
    //     return
    //   } else {
    //     file.status = 'uploading';
    //     file.message = '';
    //     const formData = new FormData();
    //     formData.append('file', file.file);
    //     formData.append('secret', 'test');
    //     testdriveUpload(formData).then(res => {
    //       this.fileList.push(res.objectUrl)
    //       file.status = 'done'
    //     })
    //   }

    // },
    // 点击电话按钮
    handlephine (cardinfo) {
      const callphone = cardinfo.accountPhone
      this.orgCode(callphone)
    },
    // 拨打电话
    async orgCode (callphone) {
      const res = await orgCode({ orgCode: callphone }) //accountPhone
      if (res.code === '000000') {
        Toast('Calling')
      } else {
        Toast(res.msg)
      }
    },
    // 跳转至操作记录
    onClickRight () {
      this.$router.push({
        path: '/testDriveOperatingRecord',
        query: { 'id': this.id }
      })
    },
    // 修改试驾
    amendTestdrive () {
      this.$router.push({
        path: '/testDriveCreate',
        query: {
          id: this.id
        }
      })
    },
    // 跳转到分配页面
    changePilot () {
      this.$router.push({
        path: '/coPilotAllot',
        query: {
          code: this.dataDetail.storeCode,
          id: this.id
        }
      })
    },
    // 完成试驾
    completed () {
      Dialog.confirm({
        message: '确认完成试驾?'
      }).then(async () => {
        if (this.model.testDriveProtocol.length === 0) {
          Toast('请先上传试驾协议')
        } else {
          this.model.testDriveProtocol.forEach(el => {
            this.fileList.push(el.url)
          })
          /* Toast.loading({
            message: 'Loading...',
            forbidClick: true,
            loadingType: 'spinner',
            overlay: true
          }) */
          const res = await changeStatus(this.id, { testDriveStatus: 'COMPLETED', 'testDriveProtocol': this.fileList })
          Toast.clear()
          if (res.success) {
            Toast('完成试驾成功')
            this.totalSize = 0
            this.fileList = []
            this.model.testDriveProtocol = []
            this.getTestdriveDetail()
          } else {
            Toast(res.msg)
          }
        }
      }).catch(() => {
        console.log('取消了');
      })

    },
    // 确认试驾
    confirmed () {
      Dialog.confirm({
        message: '确认客户以到店，开始试驾'
      }).then(async () => {
        /* Toast.loading({
          message: 'Loading...',
          forbidClick: true,
          loadingType: 'spinner',
          overlay: true
        }) */
        const res = await changeStatus(this.id, { testDriveStatus: 'CONFIRMED' })
        Toast.clear()
        if (res.success) {
          Toast('确认试驾成功')
          this.getTestdriveDetail(this.model.testDriveProtocol)
        } else {
          Toast(res.msg)
        }
      }).catch(() => {
        console.log('取消');
      })
    },
    // 取消试驾
    cancelled () {
      Dialog.confirm({
        message: '确认取消排程?一旦取消不可撤销'
      })
        .then(async () => {
          /* Toast.loading({
            message: 'Loading...',
            forbidClick: true,
            loadingType: 'spinner',
            overlay: true
          }) */
          const res = await changeStatus(this.id, { testDriveStatus: 'CANCELLED' })
          Toast.clear()
          if (res.success) {
            Toast('取消成功')
            this.getTestdriveDetail()
          } else {
            Toast(res.msg)
          }
        })
        .catch(() => {
          console.log('取消')
        });

    },
    // 获取页面传递过来的id
    getQuery () {
      this.userInfoId = this.$storage.get('userInfo').idmUserid
      this.id = this.$route.query.id
    },
    // 获取试驾详情
    async getTestdriveDetail (confirmProtocol) {
      /* Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      }) */
      const { data } = await testdriveDetail(this.id)
      Toast.clear()
      this.testdriveType.forEach(el => {
        if (el['value'] === data['testDriveType']) {
          data['type'] = el['key']
        }
      })
      this.testdriveStatus.forEach(el => {
        if (el['value'] === data['testDriveStatus']) {
          data['status'] = el['key']
        }
      })
      if (!data['coPilotName']) {
        Vue.set(this.fieldData[0]['data'][3], 'visible', false)
      } else {
        Vue.set(this.fieldData[0]['data'][3], 'visible', true)
      }
      this.dataDetail = data
      const modelData = JSON.parse(JSON.stringify(data))
      if (confirmProtocol) modelData.testDriveProtocol = confirmProtocol
      else if (data.testDriveProtocol && data.testDriveProtocol.length > 0) {
        modelData.testDriveProtocol = []
        data.testDriveProtocol.forEach(e => modelData.testDriveProtocol.push({ url: e }))
      } else modelData.testDriveProtocol = []
      this.model = modelData
    },
    /* 返回状态 */
    getStatus () {
      const status = this.$storage.get('baseData')
      this.testdriveType = status['testdrive_type']
      this.testdriveStatus = status['testdrive_status']
    },
    async init () {
      await this.getQuery()
      await this.getStatus()
      await this.getTestdriveDetail()
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="less" scoped>
/deep/ .van-nav-bar__text {
  color: #5f5f6a;
}
/deep/ .van-field[label*="试驾协议"] {
  .van-field__label {
    color: #000;
  }
}
.user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  height: 160px;
  background-color: #f5f6ff;

  .user-detail {
    display: flex;
    align-items: center;
    flex: 0 1 60%;

    .user-avator {
      flex: 0 1 80px;
      height: 80px;
      margin-right: 17px;
      border: 3px solid #fff;
      border-radius: 50%;
      box-sizing: border-box;
      background-color: #edeef0;
      overflow: hidden;
    }

    .user-info {
      height: 80px;

      .user-info-name {
        line-height: 45px;
        font-size: 32px;
        font-weight: bold;
        color: #333;
        margin-bottom: 16px;
      }
      .user-info-mobile {
        font-size: 24px;
        line-height: 33px;
        color: #666;
      }
    }
  }

  .user-contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 152px;

    .img-wrap {
      width: 56px;
      height: 56px;
      border-radius: 50%;

      // &:nth-of-type(1) {
      //   background: #ebeef0 url("../../assets/images/icon/icon_qiyewx@2x.png")
      //     center no-repeat;
      //   background-size: 28px;
      // }

      &:nth-of-type(2) {
        background: #ebeef0 url("../../assets/images/icon/icon_mobile2@2x.png")
        center no-repeat;
        background-size: 28px;
        a {
          display: block;
          height: 100%;
        }
      }
    }
  }
}

.footer {
  height: 182px;
  line-height: 98px;
  font-size: 32px;
  background-color: #fff;

  .footer-wrap {
    display: flex;
    justify-content: space-between;
    padding: 24px 46px;
    height: 100%;

    button {
      // flex: 0 1 2.026667rem;
      height: unset;
      // height: 0.773333rem;
      padding: 10px 10px;
      border-radius: 40px;
      font-size: 0.373333rem;
      background-color: transparent;
    }

    button.cancel {
      color: #ff2e2d;
      border: 2px solid;
    }

    button.opption {
      color: #DA3738;
      border: 2px solid;
    }

    button.complit {
      // width: 148px;
      border: 0;
      color: #fff;
      background-color: #DA3738;
    }
  }
}
</style>